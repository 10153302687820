import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import globalParams from '@/store/modules/globalParams.module.js';
import SecureLS from "secure-ls";
const secureLocalStorage = new SecureLS({encodingType:'aes'});


const persistedStatePlugin = createPersistedState({
  paths: ['globalParams'],
  storage: {
    getItem: (key) => secureLocalStorage.get(key),
    setItem: (key, val) => secureLocalStorage.set(key, val),
    removeItem: (key) => secureLocalStorage.remove(key),
  }
})


export default createStore({
  modules: {
    globalParams
  },
  plugins: [persistedStatePlugin]
})







