import axios from "axios";
import router from "@/router";

const instance = axios.create({
    baseURL: "https://himinfo.net/",
    headers: {
        'Content-Type': 'application/json'
    }
})

instance.interceptors.response.use(
    function (response){

        if (response.data.error === 2) {
            router.push('/')
        }
        return response.data;
    }
)

export default instance;
