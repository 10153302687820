import instance from "./axiosSingleton.js";
import sha1 from 'js-sha1';


export function getDataRequest(securecode,sessionID,refreshID,withPhoto) {
    return instance({
        method: 'POST',
        url: "him_general_php/him/GetCleanTV.php",
        data: {
            "securecode": securecode,
            "SessionID": sessionID,
            "RefreshID": refreshID,
            "with_photo": withPhoto,
        }
    });
}

export function authRequest(securecode,user,password) {
    return instance({
        method: 'POST',
        url: "him_general_php/him/GetCleanTV.php",
        data: {
            "securecode": securecode,
            "User": user,
            "Password": sha1(password)
        }
    });
}
