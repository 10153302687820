import {change_screen_mode,set_frequency,set_auth_data,set_services} from "@/store/types/globalParams.types.js";
import {authRequest,getDataRequest} from "@/requests/requests.js";
import router from "@/router";

const state = {
    services : [],
    securecode: "",
    sessionID: "",
    refreshID: "",
    screenMode : {
        withScreen : true,
        withoutScreen: false,
        statistic: false
    },
    updateParametr: 60000
}

const getters = {
    lingering: (state) => {
        return state.services.filter(item => item.delay_type === 2).length;
    },
    overdue: (state) => {
        return state.services.filter(item => item.delay_type === 1).length;
    },
    inWork: (state) => {
        return state.services.filter(item => item.delay_type === 0).length;
    },
    statisticList : (state) => {
        let tmp = [];
        state.services.forEach(item => {
            tmp.push(item.date_out.split('%')[0]);
        })
        let statisticArr = new Set(tmp);
        return statisticArr
    }
}

const mutations = {
    [change_screen_mode](state,screenName) {

        state.screenMode[screenName] = true;

        for (let key in state.screenMode)
            if(key != screenName)
                state.screenMode[key] = false;

    },
    [set_frequency](state,frequency) {
        state.updateParametr = frequency;

    },
    [set_auth_data](state,data){
        state.securecode = data.securecode;
        state.sessionID = data.sessionID;
        state.refreshID = data.refreshID;
    },
    [set_services](state,services){
        state.services = services;
    },

}

const actions = {
    changeScreenMode({commit},screenName){
        commit(change_screen_mode,screenName);
    },
    setFrequency({commit},frequency){
        commit(set_frequency,frequency);
    },
    getData({commit},data){
        getDataRequest(data.securecode,data.sessionID,data.refreshID,data.withPhoto).then(res => {
            if(res.error === 0) {
                commit(set_auth_data, {
                    securecode: data.securecode,
                    sessionID: res.SessionID,
                    refreshID: res.RefreshID
                });
                commit(set_services, res.Services);
            }
        })
    },
    auth({commit,state},data){
        authRequest(data.securecode, data.user, data.password).then(res => {
            if(res.error === 0) {
                commit(set_auth_data, {
                    securecode: data.securecode,
                    sessionID: res.Session_id,
                    refreshID: res.Refresh_id
                });

                getDataRequest(state.securecode,state.sessionID,state.refreshID,state.screenMode.withScreen === true ? 1 : 0).then(res => {
                    if(res.error === 0) {
                        commit(set_auth_data, {
                            securecode: state.securecode,
                            sessionID: res.SessionID,
                            refreshID: res.RefreshID
                        });
                        commit(set_services, res.Services);
                    }
                })

                router.push('/params')
            }
        })
    },
    exit({commit}){
        commit(set_auth_data, {
            securecode: "",
            sessionID: "",
            refreshID: ""
        });
        router.push('/');
    }
}

export default  {
    namespaced:true,
    state,
    mutations,
    actions,
    getters
}
